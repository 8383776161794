<script>
import {appDown} from  "@/api/home";
export default {    
    components:{
        Header: () => import('components/base/Header'),
    },
    data(){
        return{
            android:false,
            type:"",//1 IOS 2安卓
            result:'',
            imgUrlTitle:'//cpfiles.'+process.env.VUE_APP_PROD_DOMAIN+'/appDown/',//oss图片地址头
            qrSize:(parseFloat(document.documentElement.style.fontSize)/50)*180,

            header:{
                mainTitle: '',
            },
            logoUrl:null,
            maintitle:null,
            phase:1,
            iosDownload:null,
        }
    },
    methods:{
        ieClick(){
            this.$router.replace({path:'/home'});
        },
        appDowm(){
            window.location.href=this.result;
        },

        downloadApp(type){
            var type=type==='android'?'2':'1';
            if (type==='1') {
                this.iosDownload=true;
                return false;
            }
            appDown(type).then(result => {
                if(result.data.code=='SUCCESS'){
                    if(type === '1'){
                        this.result='itms-services://?action=download-manifest&url='+result.data.result.url; 
                    }else if(type === '2'){
                        window.location.href=result.data.result.url; 
                    }
                }else{
                    app.Mint.Toast(result.data.msg);
                }
            })
        },
        changePhase(index) {
            this.phase=index;
        }
    },
    mounted(){
        this.CustomScroll(this.$refs.download.id);
        // this.isPhone();
        if (window.localStorage.getItem('logourl')) {
            this.logoUrl=window.localStorage.getItem('logourl');
        }        
        if (window.localStorage.getItem('maintitle')) {
            this.maintitle=window.localStorage.getItem('maintitle');
        }
    }
};
</script>

<template>
    <section id="download" ref="download">
        <Header
            :mainTitle="header.mainTitle"
            :backButton=false
            :hideInfo=true
        />
        <template>
            <img :src="require('@@/assets/images/home/app/manual.jpg')" alt="" class="guide_image">
        </template>
    </section>
</template>

<style scoped lang='scss' type="text/css">
#download {
    overflow: auto;
    background-image: url('/static/assets/images/home/app/qrcode-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;

    img {
        max-width: 100%;
    }

    .qrcode {
        background-color: #ffffff;
        margin: 0 auto;
        width: 85%;
        border-radius: .33rem;
        margin-top: 2rem;

        .inner {
            text-align: center;
            padding: .5rem 0 1rem;

            h3 {
                font-size: .36rem;
                font-weight: normal;
                margin-bottom: .5rem;
            }

            h5 {
                margin-top: .5rem;
                font-size: .28rem;
                color: #999999;
            }
        }
    }
}
</style>